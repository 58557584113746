.layout {
  :global {
    Header {
      display: flex;
      flex-direction: row;

      .title {
        color: white;
        margin-right: 32px;
      }
    }


    Footer {
      text-align: center;
    }

    code {
      margin: 0 .5em;
      padding: .1em .5em .1em;
      font-size: 85%;
      background: rgba(150, 150, 150, .1);
      border: 1px solid rgba(100, 100, 100, .2);
      border-radius: 2px;
    }

    .content {
      padding: 50px;

      .cover {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }

      .downloads {
        margin-bottom: 8px;

        a {
          margin-right: 16px;
        }
      }

      .ant-table-cell {
        vertical-align: top;
      }
    }
  }

  @media screen and (max-width: 640px) {
    :global {
      .content {
        padding: 16px;

        .cover {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .downloads {
          a {
            margin-right: 16px;
            margin-bottom: 8px;
          }
        }
        .ant-table-cell{
          padding: 8px;
        }
      }
    }
  }
}
